<template>
  <div class="userInfo">
    <el-descriptions title="用户信息">
      <el-descriptions-item label="邮箱账户">{{user.username}}</el-descriptions-item>
      <el-descriptions-item label="企业名称">{{user.company}}</el-descriptions-item>
      <el-descriptions-item label="权限">{{user.rightLevel===0?"管理员用户":"普通用户"}}</el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script>
export default {
    data(){
        return {
            user:this.$store.getters.user
        }
    }
};
</script>

<style  scoped>
.userInfo{
    text-align: center;
    height: 10%;
    width: 70%;
    margin: 5%;
}
</style>